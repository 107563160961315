import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import Flex from "app/components/Flex";
import { LineSeparator } from "app/components/SideNavbar/lines";
import { useGetChallengeById } from "hooks/Challenges";
import useGetAcknowledgedContent from "hooks/useGetAcknowledgedContent";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import SubmitBar from "./SubmitBar";
import Cover from "./Cover";
import About from "./About";
import Info from "./Info";
import SubmissionsList from "./SubmissionsList";

export default () => {
  const { id } = useParams();
  const [
    { progress: userProgress },
    getAcknowledgedContentLoading,
    getAcknowledgedContentError,
  ] = useGetAcknowledgedContent();
  const [data, getChallengeLoading, getChallengeError] = useGetChallengeById({
    variables: { id },
  });

  const hasAcceptedTerms = useMemo(
    () =>
      userProgress?.acknowledgedContent?.challenges?.["terms-and-conditions"],
    [userProgress]
  );
  const loading = getAcknowledgedContentLoading || getChallengeLoading;
  const error = getAcknowledgedContentError || getChallengeError;

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return <Redirect to="/not-found" />;
  }

  if (!hasAcceptedTerms) {
    return <Redirect to="/community" />;
  }

  const { submissionsCount, isActive } = data;

  const containerWidth = submissionsCount
    ? { _: "97%", sm: "auto" }
    : { _: "97%", sm: "85%", lg: "60%" };

  return (
    <Flex
      flexDirection="column"
      textAlign="left"
      width="100%"
      alignItems="center"
    >
      <Cover />
      <Flex flexDirection="column" width={containerWidth} px={2} pb={88}>
        <About />
        <LineSeparator />
        <Info />
        <SubmissionsList />
      </Flex>
      <SubmitBar classId={data.forClass.id} challengeActive={isActive} />
    </Flex>
  );
};
