import React from "react";
import { useParams } from "react-router";
import { isEmpty } from "lodash";
import { useGetChallengeById } from "hooks/Challenges";
import UserSubmissionsList from "app/components/Challenges/UserSubmissionsList";
import Flex from "app/components/Flex";
import { H4, P1 } from "app/components/Typography";
import { ANALYTICS_MODULE } from "constants/index";

export default () => {
  const { id } = useParams();
  const [
    {
      id: challengeId,
      submissionsCount = 0,
      videos: { edges = [], totalCount } = {},
    },
    ,
    ,
    fetchMore,
  ] = useGetChallengeById({
    variables: { id },
  });

  if (!submissionsCount && isEmpty(edges)) {
    return <></>;
  }

  const hasMore = edges.length < totalCount;

  return (
    <>
      <Flex width="100%" mt={5} mb={3}>
        <Flex width="100%" justifyContent="space-between">
          <H4>Submissions</H4>
          <P1>{submissionsCount}</P1>
        </Flex>
      </Flex>

      <UserSubmissionsList
        videos={edges}
        hasMore={hasMore}
        fetchMore={fetchMore}
        challengeId={challengeId}
        fromModule={ANALYTICS_MODULE.challenge_detail}
      />
    </>
  );
};
