import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import ReportOverlay from "app/components/Challenges/ChallengesComponents/ReportOverlay";
import { ReactComponent as VideoCameraBackground } from "app/assets/svg/video-camera-background.svg";
import { P1 } from "app/components/Typography";
import Text from "app/components/Text";
import env from "helpers/env";
import { OverflowText } from "./styles";

const UserSubmissionsItem = ({ key, submission, openModal }) => {
  const {
    description,
    thumbnailUrl,
    encodingStatus,
    reportedByMe,
  } = submission;
  const onClick = () => openModal(submission);
  const isEncoding = encodingStatus !== "available";

  return (
    <Flex
      justifyContent="center"
      onClick={onClick}
      key={key}
      cursor={isEncoding ? "default" : "pointer"}
    >
      <Flex flexDirection="column">
        <Flex position="relative" flexDirection="column">
          <Thumbnail
            as={isEncoding && VideoCameraBackground}
            src={thumbnailUrl}
            placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/challenges/black.jpg`}
            width={{ _: "356px", md: "248px" }}
            mb="12px"
            borderRadius="5px"
          />
          {isEncoding && (
            <Text
              position="absolute"
              color="white"
              fontSize={{ _: "20px", md: "14px" }}
              maxWidth="70%"
              top="43%"
              left="15%"
              textAlign="center"
            >
              Your video is still processing. Check back in a few minutes!
            </Text>
          )}
          {reportedByMe && (
            <ReportOverlay iconWidth="40px" iconHeight="40px">
              <P1 color="white" mt={3}>
                Post Reported
              </P1>
            </ReportOverlay>
          )}
        </Flex>
        <Flex
          height="40px"
          maxHeight="40px"
          maxWidth={{ _: "356px", md: "248px" }}
          mb={3}
        >
          <OverflowText>{description}</OverflowText>
        </Flex>
      </Flex>
    </Flex>
  );
};

UserSubmissionsItem.propTypes = {
  key: PropTypes.string.isRequired,
  submission: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default UserSubmissionsItem;
