import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "app/components/Grid";
import { Waypoint } from "app/components/Waypoint";
import { UserVideoModal } from "app/components/UserVideos/UserVideoModal";
import Submission from "./UserSubmissionsItem";

const UserSubmissionsList = ({
  videos = [],
  fetchMore = () => {},
  hasMore,
  fromModule,
}) => {
  const [userVideoId, setUserVideoId] = useState(null);
  const openModal = ({ id, encodingStatus }) => {
    if (encodingStatus !== "available") {
      return;
    }
    setUserVideoId(id);
  };

  return (
    <>
      <Grid
        position="relative"
        width="100%"
        justifyContent="center"
        gridGap="6px"
        gridTemplateColumns={{
          _: "repeat(1, minMax(304px, 1fr))",
          xs: "repeat(1, minMax(343px, 1fr))",
          md: "repeat(3, minMax(248px, 1fr))",
        }}
      >
        {videos.map(({ node }) => (
          <Submission key={node.id} submission={node} openModal={openModal} />
        ))}
      </Grid>
      <Waypoint fetchData={fetchMore} hasMore={hasMore} />
      {!!userVideoId && (
        <UserVideoModal
          userVideoId={userVideoId}
          closeModal={() => setUserVideoId(null)}
          fromModule={fromModule}
        />
      )}
    </>
  );
};

UserSubmissionsList.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  fromModule: PropTypes.string.isRequired,
};

export default UserSubmissionsList;
